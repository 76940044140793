.App {
  text-align: center;
}
.ant-table .ant-table-container .ant-table-content table thead.ant-table-thead .ant-table-cell  {
  background-color: #3D76DD;
  color: #fff;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
.logo {
  width: 100%;
  max-width: 750px;
  height: 350px;
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
.VerifiedRow {
  background-color: #E9FCE9;
}
label{
  font-weight: bold;
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@media (max-width: 768px) {
  .hide-on-mobile {
    display: none;
  }
  .display-on-mobile {
    display: block;
  }
}
@media screen and (min-width: 1100px) {
  .hide-on-desktop {
    display: none;
  }
}
/* td {
  border: 1px solid black;
}
tr {
  border: 1px solid black;
} */
